export const MONTHS_STRINGS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Aout',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

export const WEEKDAYS_LONG = [
  'Dimanche',
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
];

export const WEEKDAYS_SHORT = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];

export const arrayToObject = (arr, key = 'appId') => {
  const obj = {};
  arr.forEach(element => {
    obj[element[key]] = element;
  });
  return obj;
};
export function mapFiltersFromTableData(arrays, keys) {
  let result = {}
  for (let i = 0; i < keys.length; i++) {
    const values = arrays.map(item => item[keys[i]]).filter(item => !!item)
    result[keys[i]] = values.filter(function (item, pos) {
      return values.indexOf(item) == pos;
    })
  }
  return result
}
export function includesSearchWord(title, searchWord) {
  if (searchWord === '') {
    return true;
  }
  return (
    String(title).toLowerCase().indexOf(String(searchWord).toLowerCase()) >= 0
  );
}

export function removeDuplicateFromArray(a, key) {
  return a.filter((value, index, self) =>
    index === self.findIndex((t) => (
      key ? t[key] == value[key] : t == value
    ))
  )
}
