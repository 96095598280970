import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Select, MenuItem } from '@material-ui/core';
import clsx from 'classnames';
import * as API from 'services/api';
import { injectIntl, FormattedMessage } from 'react-intl';
import { NO_CLIENT_ID } from 'config';
import CustomCommonSelect from 'components/CustomCommonSelect';

const useStyles = makeStyles(theme => ({
  field: {

  },
  labelStyle: {
    marginTop: 0
  }
}));

const ClientSelect = ({
  clientId,
  setClientId,
  intl: { formatMessage },
  addFree,
  className,
  shortLabel,
  showAll,
  disabled,
  label
}) => {
  const [clients, setClients] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    API.listClients()
      .then(res => {
        if (res.code === 200) {
          if (addFree) {
            setClients([
              {
                id: NO_CLIENT_ID,
                company: formatMessage({
                  id: `no_client${shortLabel ? '_short' : ''}`,
                }),
              },
              ...res.data,
            ]);
          } else {
            setClients(res.data);
            // if (res.data.length === 1) {
            //   setClientId(res.data[0].id);
            // }
          }
        }
      })
      .catch(err => { });
  }, []);

  return (
    clients.length > 0 && (
      <CustomCommonSelect
        className={clsx(className, classes.field)}
        fullWidth
        placeholder={formatMessage({ id: 'home.user.select_company' })}
        value={clientId}
        onChange={e => {
          const id = e.target.value;
          setClientId(id);
        }}
        disabled={disabled}
        variant="outlined"
        hideLabel={!label}
        label={label}
        labelStyle={classes.labelStyle}
      >
        <MenuItem value={-1} disabled={!showAll}>
          <FormattedMessage
            id={showAll ? 'all' : 'select_client_from_the_list'}
          />
        </MenuItem>
        {clients.sort((a, b) => (a.company || "").localeCompare(b.company || "")).map(client => (
          <MenuItem key={client.id} value={client.id}>
            {client.company}
          </MenuItem>
        ))}
      </CustomCommonSelect>
    )
  );
};

ClientSelect.propTypes = {
  clientId: PropTypes.any.isRequired,
  setClientId: PropTypes.func.isRequired,
  onClientsLoaded: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
  addFree: PropTypes.bool,
  showAll: PropTypes.bool,
  shortLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.any,
};

ClientSelect.defaultProps = {
  addFree: false,
  shortLabel: false,
  showAll: false,
  className: null,
  disabled: false,
};

export default injectIntl(ClientSelect);
