import React from 'react';
import { makeStyles, withStyles, Tabs, Tab } from '@material-ui/core';

export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

export const StyledTabs = withStyles({
    root: {
        borderRadius: 15,
        backgroundColor: 'white',
        padding: 10,
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 16
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#040f40',
        width: 140,
        height: 0,
        borderRadius: 4,
        '& > span': {
            backgroundColor: '#635ee7',
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: "#93A1BD",
        //fontSize: theme.typography.pxToRem(15),
        textAlign: 'left',
        padding: theme.spacing(0, 2),
        marginRight: theme.spacing(1),
        minWidth: 'auto',
        '&:focus': {
            opacity: 1,
        },
        minHeight: 38
    },
    wrapper: {
        alignItems: 'flex-start'
    },
    selected: {
        backgroundColor: "#E7EBF8",
        borderRadius: 8,
        color: "#12274C",
        fontWeight: '700'
    }
}))((props) => <Tab disableRipple {...props} />);
