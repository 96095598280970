import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  IconButton,
  CircularProgress,
  Paper,
  Tooltip,
  Popover,
  ListItem,
  ListItemText,
  Chip,
  Grid
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import EmojiIcon from '@material-ui/icons/SentimentSatisfied';
import IconAttachment from '@material-ui/icons/AttachFile'
import Picker from 'emoji-picker-react';
import IconShare from '@material-ui/icons/Share';
import { injectIntl } from 'react-intl';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, EditorState, convertToRaw, Modifier, RichUtils } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import { ReactComponent as IconBold } from 'assets/svgs/chat_bold.svg';
import { ReactComponent as IconItalic } from 'assets/svgs/chat_italic.svg';
import { ReactComponent as IconUnderline } from 'assets/svgs/chat_underline.svg';
import { ReactComponent as IconDotorder } from 'assets/svgs/chat_dotorder.svg';
import { ReactComponent as IconNumorder } from 'assets/svgs/chat_numorder.svg';
import { ReactComponent as IconFile } from 'assets/svgs/chat_file.svg';
import { ReactComponent as IconEmoji } from 'assets/svgs/chat_emoji.svg';
import { ReactComponent as IconMic } from 'assets/svgs/chat_mic.svg';
import { ReactComponent as IconDoc } from 'assets/svgs/doc_file.svg';
import { sendMessage } from 'services/api'
import CloseIcon from '@material-ui/icons/Close';

let initialEditorState;
const html = '';
const contentBlock = htmlToDraft(html);
if (contentBlock) {
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  initialEditorState = EditorState.createWithContent(contentState);
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.app.background,
    padding: theme.spacing(2),
    border: `1px solid #E5E3F2 !important`,
    borderRadius: `5px 5px 10px 10px`
  },
  editor: {
    minHeight: '80px !important',
    backgroundColor: '#F2F5FB'
  },

  paper: {
    flexGrow: 1,
    boxShadow: 'none',
  },
  input: {
    width: '100%',
  },
  divider: {
    width: 1,
    height: 24,
  },
  fileInput: {
    display: 'none',
  },
  popover: {
    zIndex: `999999 !important`
  },
  mentionModal: {
    backgroundColor: '#ADDCED',
    color: '#5764AB'
  },
  iconBtn: {
    backgroundColor: 'transparent',
    margin: theme.spacing(0, 0.8),
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent !important',
      fill: theme.palette.app.primary,
      color: theme.palette.app.primary
    }
  },
  chipname: {
    color: '#464F73',
    fontSize: 12
  },
  deleteIcon: {
    width: 20,
    height: 20,
    color: '#464F73'
  },
  chip: {
    backgroundColor: '#F2F5FB',
    border: '1px solid #E5E3F2',
    borderRadius: 8,
    margin: 4
  },
  chipIcon: {
    width: `20px !important`,
    height: `20px !important`,
  }
}));


const ConversationForm = props => {
  const { className, intl: { formatMessage }, requestId, loadMessages,  ...rest } = props;
  const [editorState, setEditorState] = useState(initialEditorState);
  const [files, setFiles] = useState([]);

  const classes = useStyles();

  const fileInputRef = useRef(null);
  const menuRef = useRef();
  const [openMenu, setOpenMenu] = useState(false);
  const [loading, setLoading] = useState(false)

  const onEditorStateChange = newValue => {
    setEditorState(newValue);
  };

  const handleAttach = () => {
    fileInputRef.current.click();
  };
  const onEmojiClick = (event, emojiObject) => {
    setOpenMenu(false)

    let sym = emojiObject.unified.split('-')
    let codesArray = []
    sym.forEach(el => codesArray.push('0x' + el))
    let emoji = String.fromCodePoint(...codesArray)

    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      emoji
    );

    const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
    setEditorState(newEditorState)
  };
  const submit = (files) => {
    let message = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    let file = null
    let asset = null
    if (files && files.length > 0) {
      message = 'Share File'
      file = files[0]
    }
    if (message && message.length > 0 && message.trim() != "<p></p>") {
      sendMessage({
        message,
        requestId: requestId.id,
        unread: false
      }).then(res => {
        if (res.code == 201) {
          setEditorState(initialEditorState)
          loadMessages()
        }
      })
    }
  };

  const onChangeFile = (event) => {
    event.preventDefault();
    if (event.target.files.length > 0) {
      const [selectedFile] = event.target.files;
      setFiles([...files, selectedFile])
      event.target.value = null;
    }
  }
  const toggleInlineStyle = inlineStyle => {
    let currentContent = editorState.getCurrentContent();
    const selectionBefore = editorState.getSelection();

    const selection = editorState.getSelection().merge({
      anchorKey: currentContent.getFirstBlock().getKey(),
      anchorOffset: 0,
      focusOffset: currentContent.getLastBlock().getText().length,
      focusKey: currentContent.getLastBlock().getKey()
    });

    const editorStateWithAllSelection = EditorState.acceptSelection(
      editorState,
      selection
    );

    const newState = RichUtils.toggleInlineStyle(
      editorStateWithAllSelection,
      inlineStyle
    );

    const editorStateWithSelectionBefore = EditorState.acceptSelection(
      newState,
      selectionBefore
    );

    setEditorState(editorStateWithSelectionBefore);
  };

  const toggleBlockType = type => {
    let currentContent = editorState.getCurrentContent();
    const selectionBefore = editorState.getSelection();

    const selection = editorState.getSelection().merge({
      anchorKey: currentContent.getFirstBlock().getKey(),
      anchorOffset: 0,
      focusOffset: currentContent.getLastBlock().getText().length,
      focusKey: currentContent.getLastBlock().getKey()
    });

    const editorStateWithAllSelection = EditorState.acceptSelection(
      editorState,
      selection
    );

    const newState = RichUtils.toggleBlockType(
      editorStateWithAllSelection,
      type
    );

    const editorStateWithSelectionBefore = EditorState.acceptSelection(
      newState,
      selectionBefore
    );

    setEditorState(editorStateWithSelectionBefore);
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Paper
        className={classes.paper}
        elevation={1}
      >
        <Editor
          toolbarHidden={true}
          editorClassName={classes.editor}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          wrapperClassName="demo-wrapper"
        />
      </Paper>
      <Popover
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        anchorEl={menuRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
        className={classes.popover}
      >
        <div className={classes.popoverroot}>
          <Picker onEmojiClick={onEmojiClick} />
        </div>
      </Popover>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Grid container alignItems="center">
            <IconButton className={classes.iconBtn} onClick={() => toggleInlineStyle('BOLD')}><IconBold /></IconButton>
            <IconButton className={classes.iconBtn} onClick={() => toggleInlineStyle('ITALIC')}><IconItalic /></IconButton>
            <IconButton className={classes.iconBtn} onClick={() => toggleInlineStyle('UNDERLINE')}><IconUnderline /></IconButton>
            <IconButton className={classes.iconBtn} onClick={() => toggleBlockType('ordered-list-item')}><IconNumorder /></IconButton>
            <IconButton className={classes.iconBtn} onClick={() => toggleBlockType('unordered-list-item')}><IconDotorder /></IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <IconButton color={'primary'} onClick={() => submit()} className={classes.iconBtn}>
              <SendIcon />
            </IconButton>
            {
              loading ?
                <CircularProgress size={24} className={classes.iconbtn} /> :
                <IconButton
                  onClick={handleAttach}
                  className={classes.iconBtn}>
                  <IconFile />
                </IconButton>
            }
            <IconButton className={classes.iconBtn} ref={menuRef} onClick={() => setOpenMenu(true)}><IconEmoji /></IconButton>
            {/* <IconButton className={classes.iconBtn}><IconMic /></IconButton> */}
          </Grid>
        </Grid>
      </Grid>
      {
        files.length > 0 && files.map((item, index) => {
          return <Chip
            key={index + ''}
            avatar={<IconDoc className={classes.chipIcon} />}
            label={`${item.name}`}
            onDelete={() => {
              setFiles(files.filter((el, fx) => fx != index))
            }}
            className={classes.chip}
            classes={{ deleteIcon: classes.deleteIcon }}
            deleteIcon={<CloseIcon />}
          />
        })
      }
      <input
        className={classes.fileInput}
        ref={fileInputRef}
        id="chat-file"
        type="file"
        onChange={onChangeFile}
      />
    </div>
  );
};

ConversationForm.propTypes = {
  className: PropTypes.string,
};

export default injectIntl(ConversationForm);
