
import React, { createContext, useContext, useCallback, useEffect, useRef, useState } from 'react';
import { ROLE_APAVE_CLIENT, ROLE_ADMIN } from 'config';
import _ from 'lodash';
import { appProfileSelector } from 'redux/selectors';
import { useSelector } from 'react-redux';

export default function useHomeContext() {
    const context = useContext(HomeContext);
    if (!context) {
        throw new Error('useHomeContext must be used within a HomeProvider');
    }
    return context;
}

const HomeContext = createContext();

export const HomeProvider = ({ children }) => {
    const {
        role,
        adminPermissions
    } = useSelector(appProfileSelector);

    const hasPermission = (path) => {
        if (role == ROLE_APAVE_CLIENT) {
            try {
                return adminPermissions && path ? _.get(adminPermissions, path, false) : false
            } catch (error) {
                return false
            }
        } else if (role == ROLE_ADMIN) {
            return true;
        }
    }
    return (
        <HomeContext.Provider
            value={{
                hasPermission
            }}
        >
            {children}
        </HomeContext.Provider>
    );
};
