import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Avatar, colors } from '@material-ui/core';
import MediaMessage from './media'
import AssetMessage from './asset'
import { injectIntl } from 'react-intl';
import { appProfileSelector } from 'redux/selectors';
import { useSelector } from 'react-redux';
import { profileURL } from 'config';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  authUser: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& $body': {
      backgroundColor: '#344563',
      color: theme.palette.primary.contrastText,
    },
  },
  inner: {
    maxWidth: 500,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  body: {
    backgroundColor: '#F2F5FB',
    color: theme.palette.text.primary,
    borderRadius: 15,
    borderBottomRightRadius: 0,
    padding: theme.spacing(1, 2),
  },
  otherBody: {
    borderRadius: 15,
    borderTopLeftRadius: 0,
  },
  content: {

  },
  image: {
    marginTop: theme.spacing(2),
    height: 'auto',
    width: 380,
    maxWidth: '100%',
  },
  footer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  message: {
    textAlign: 'left'
  },
  username: {
    textAlign: 'left',
    color: theme.palette.app.primary,
    fontSize: 16
  },
  time: {
    color: '#6E7697',
    fontSize: 12
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  }
}));

const ConversationMessage = props => {
  const { message, className, intl: { formatMessage }, ...rest } = props;

  const classes = useStyles();
  const {
    id,
    picture,
    firstName,
    lastName
  } = useSelector(appProfileSelector);
  const other_user = id != message.userId
  const sender = !message.userId ? {
    firstName: message.guest?.firstName || "",
    lastName: message.guest?.lastName || "",
  } : {
    picture: other_user ? message.picture : picture,
    firstName: other_user ? message.firstName : firstName,
    lastName: other_user ? message.lastName : lastName
  }

  return (
    <div
      {...rest}
      className={clsx(
        classes.root,
        {
          [classes.authUser]: !other_user,
        },
        className,
      )}
    >
      <div className={classes.inner}>
        {
          other_user &&
          <div className={classes.header}>
            < Avatar
              className={classes.avatar}
              component={RouterLink}
              src={profileURL + sender?.picture}
            />
            <Typography
              color="primary"
              variant="h6"
              className={classes.username}
            >
              <strong>{`${sender?.firstName || ''} ${sender?.lastName || ''}`}</strong> &nbsp;&nbsp;
            </Typography>
            <Typography
              className={classes.time}
              variant="body2"
            >
              {moment(message.dateCreated).fromNow()}
            </Typography>
          </div>
        }
        <div>
          <div className={clsx(classes.body, {
            [classes.otherBody]: other_user,
          })}>
            <div className={classes.content}>
              <div className={classes.message} dangerouslySetInnerHTML={{ __html: message.message }} />
            </div>
          </div>
          {
            !other_user &&
            <div className={classes.footer}>
              <Typography
                className={classes.time}
                variant="body2"
              >
                {moment(message.dateCreated).fromNow()}
              </Typography>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

ConversationMessage.propTypes = {
  className: PropTypes.string,
  message: PropTypes.object.isRequired,
};

export default injectIntl(ConversationMessage);