import React from 'react';
import { ROLE_ADMIN, ROLE_APAVE_CLIENT } from 'config';

import Clients from 'pages/Clients';
import Users from 'pages/Users';
import InternalUsers from 'pages/InternalUsers';
import Contacts from 'pages/Leads';

import { ReactComponent as UsersIcon } from 'assets/svgs/Contributors.svg';
import { FormattedMessage } from 'react-intl';
import UserIcon from '@material-ui/icons/Person'
import MailIcon from '@material-ui/icons/Mail'
import { ReactComponent as ExitIcon } from 'assets/svgs/Login.svg';
import CategoryIcon from '@material-ui/icons/Category'
import StorefrontIcon from '@material-ui/icons/Storefront';
import BusinessIcon from '@material-ui/icons/Domain'
import { ReactComponent as DataIcon } from 'assets/svgs/data.svg';
import { ReactComponent as IconCross } from 'assets/svgs/cross.svg';
import MenuBookIcon from '@material-ui/icons/MenuBook';

export default function GetMenus(role, hasPermission) {
  const menus = [
    {
      id: '/external',
      text: <FormattedMessage id="navbar.users.external" />,
      icon: <UsersIcon className="side-bar-svg" />,
      url: '/external',
      //components: Users,
      index: 0,
      children: [
        {
          id: 'entreprises',
          text: <FormattedMessage id="navbar.entreprises" />,
          url: '/external/clients-international',
          index: 0
        },
        {
          id: 'externalusers',
          text: <FormattedMessage id="navbar.externalusers" />,
          url: '/external/users-international',
          index: 0,
        },
        {
          id: 'notifications',
          text: <FormattedMessage id="navbar.notifications" />,
          url: '/external/notifications',
          index: 0,
        }
      ]
    },
  ];
  if (role === ROLE_ADMIN || role == ROLE_APAVE_CLIENT) {
    if (hasPermission("internal"))
      menus.push({
        id: '/internal',
        text: <FormattedMessage id="navbar.users.internal" />,
        icon: <BusinessIcon className="side-bar-svg" />,
        url: '/internal',
        components: InternalUsers,
        index: 0,
        children: [
          {
            id: 'internalusers',
            text: <FormattedMessage id="navbar.internal.users" />,
            url: '/internal/users',
            index: 0
          },
          {
            id: 'internalteams',
            text: <FormattedMessage id="navbar.internal.teams" />,
            url: '/internal/teams',
            index: 0,
          },
          {
            id: 'admins',
            text: <FormattedMessage id="navbar.internal.admins" />,
            url: '/internal/admins',
            index: 0,
          }
        ]
      });
    if (hasPermission("catalogues"))
      menus.push({
        id: 'contact',
        text: <FormattedMessage id="navbar.contacts" />,
        icon: <MailIcon className="side-bar-svg" />,
        url: '/Contacts',
        components: Contacts,
        index: 0,
      });
    if (hasPermission("catalogues"))
      menus.push({
        id: 'catalogue',
        text: <FormattedMessage id="home.client.tab.catalogue" />,
        icon: <CategoryIcon className="side-bar-svg" />,
        url: '/catalogues',
        index: 0,
        // children: [
        //   {
        //     id: 'catalogueschild',
        //     text: <FormattedMessage id="navbar.catalogues" />,
        //     url: '/catalogues/catalogue',
        //     index: 0
        //   },
        //   {
        //     id: 'recycles',
        //     text: <FormattedMessage id="navbar.recycles" />,
        //     url: '/catalogues/catalogue/recycles',
        //     index: 0
        //   }
        // ]
      });
    if (hasPermission("data"))
      menus.push({
        id: 'data',
        text: <FormattedMessage id="home.client.tab.data" />,
        icon: <DataIcon className="side-bar-svg" />,
        url: '/data',
        index: 0,
        children: [
          {
            id: 'product',
            text: <FormattedMessage id="navbar.products" />,
            url: '/data/products',
            index: 0,
            // children: [
            //   {
            //     id: 'product_api',
            //     text: <FormattedMessage id="navbar.productsAPI" />,
            //     url: '/data/products',
            //     index: 0
            //   },
            // ]
          },
          {
            id: 'session',
            text: <FormattedMessage id="navbar.sessions" />,
            url: '/data/sessions',
            index: 0,
            // children: [
            //   {
            //     id: 'session_api',
            //     text: <FormattedMessage id="navbar.sessionsAPI" />,
            //     url: '/data/sessions',
            //     index: 0
            //   },
            // ]
          },
          {
            id: 'compteSel',
            text: <FormattedMessage id="navbar.compteSel" />,
            url: '/data/compteSel',
            index: 0,
          },
          {
            id: 'inspection',
            text: <FormattedMessage id="navbar.inspection" />,
            url: '/data/inspection',
            index: 0,
            children: [
              {
                id: 'caracteristiques',
                text: <FormattedMessage id="navbar.caracteristiques" />,
                url: '/data/inspection/caracteristiques',
                index: 0
              },
              {
                id: 'equipement',
                text: <FormattedMessage id="navbar.equipement" />,
                url: '/data/inspection/equipement',
                index: 0
              },
              {
                id: 'interventionSel',
                text: <FormattedMessage id="navbar.interventionSel" />,
                url: '/data/inspection/interventionSel',
                index: 0
              },
              {
                id: 'codeproductintervention',
                text: <FormattedMessage id="navbar.codeproductintervention" />,
                url: '/data/inspection/codeproducts',
                index: 0
              },
            ]
          },
          {
            id: 'training',
            text: <FormattedMessage id="navbar.training" />,
            url: '/data/training',
            index: 0,
            // children: [
            //   {
            //     id: 'product_api',
            //     text: <FormattedMessage id="navbar.productsAPI" />,
            //     url: '/data/products',
            //     index: 0
            //   },
            // ]
          },
          {
            id: 'logs',
            text: <FormattedMessage id="navbar.logs" />,
            url: '/logs',
            index: 0,
          },
        ]
      });
    if (hasPermission("locations"))
      menus.push({
        id: 'locations',
        text: <FormattedMessage id="navbar.locations" />,
        icon: <StorefrontIcon className="side-bar-svg" />,
        url: '/locations',
        index: 0,
      });
  }

  menus.push({
    id: 'xsell',
    text: <FormattedMessage id="navbar.xsell" />,
    icon: <IconCross className="side-bar-svg" />,
    url: '/xsell',
    index: 0,
    children: [
      {
        id: 'xsellusers',
        text: <FormattedMessage id="navbar.xsell.users" />,
        url: '/xsell/users',
        index: 0,
      },
      {
        id: 'xsellsession',
        text: <FormattedMessage id="navbar.xsell.submissions" />,
        url: '/xsell/submissions',
        index: 0,
      },
    ]
  });

  menus.push({
    id: 'apidoc',
    text: <FormattedMessage id="navbar.apidoc" />,
    icon: <MenuBookIcon className="side-bar-svg" />,
    index: 0
  });
  return {
    menus,
    menus_bottom: [
      {
        id: 'profile',
        text: <FormattedMessage id="navbar.profile" />,
        icon: <UserIcon className="side-bar-svg" />,
        url: '/profile',
        index: 21,
      },
      {
        id: 'exit',
        text: <FormattedMessage id="navbar.exit" />,
        icon: <ExitIcon className="side-bar-svg" />,
        index: 22,
      },
    ]
  };
}
