import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

import ConversationMessage from '../ConversationMessage';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    textAlign: 'center',
    position: 'relative',
    "&.ps__rail-x": {
      display: 'none'
    }
  },
  inner: {
    padding: theme.spacing(0),
  },
  loading: {
    color: theme.palette.app.primary,
    marginTop: 10
  },
  scrollview: {
    overflowY: 'scroll',
    maxHeight: 'calc(100vh - 363px)'
  }
}));

const ConversationMessages = props => {
  const { className, messages, ...rest } = props;

  const classes = useStyles();
  const scrollRef = useRef(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: "smooth", block: "end" });
    }
  }, [messages]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <PerfectScrollbar className={classes.scrollview}>
        <div className={classes.inner}>
          {messages.map(message => {
            return (
              <ConversationMessage
                key={message.sid}
                message={message}
              />
            );
          })}
        </div>
        <div ref={scrollRef} />
      </PerfectScrollbar>
    </div>
  );
};

ConversationMessages.propTypes = {
  className: PropTypes.string,
  messages: PropTypes.array.isRequired,
};

export default ConversationMessages;
