import {
    TextField,
    Typography,
    Tooltip
} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles
  } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {

    },
    label: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0.5),
        color: '#061024',
        fontWeight: '600'
    },
    disabled: {
        color: theme.palette.app.primary
    }
}));

const CustomTextInput = ({
    label,
    className,
    required,
    labelStyle,
    endAdornment,
    password,
    ...rest
}) => {
    const classes = useStyles();
    const hint= `Création de votre mot de passe :
    • Longueur : les mots de passe doivent comporter au moins 8 caractères.
    • Complexité : les mots de passe doivent inclure une combinaison de lettres majuscules, de lettres minuscules, de chiffres et de caractères spéciaux (par exemple !, @, #, $, %, ^, &, *).`;
    return (
        <>
            {
                label &&
                <Typography className={clsx(classes.label, labelStyle)}>{label}{required ? <span style={{ color: '#DF7C70' }}>*</span> : ''}</Typography>
            }
            <TextField
                {...rest}
                className={clsx(classes.root, className)}
                label={''}
                InputProps={{
                    classes: { disabled: classes.disabled }, endAdornment: password ?
                        <BlueOnGreenTooltip title={<span style={{ whiteSpace: 'pre-line' }}>{hint}</span>}>
                            <InfoOutlinedIcon style={{
                                cursor: "pointer"
                            }} />
                        </BlueOnGreenTooltip>
                        : endAdornment
                }}
            />
        </>
    );
};

export default CustomTextInput;

const BlueOnGreenTooltip = withStyles({
    tooltip: {
      color: "#263238",
      backgroundColor: "white",
      fontSize: 12,
      fontWeight: "bold",
      boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)"
    }
  })(Tooltip);
