import React, { Fragment, Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { loggedInSelector, appLoadingSelector } from 'redux/selectors';
import Loader from 'components/Loader';
import SelectLanguage from 'components/SelectLanguage'

const useStyles = makeStyles(theme => ({
  content: {
    height: '100vh',
    ///backgroundImage: 'url(/images/background.jpg)',
    //backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white'
  },
  loginContainer: {
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    justifyContent: "space-between",
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    backgroundColor: theme.palette.app.primary
  },
  logo: {
    height: 55,
    backgroundColor: 'white',
    borderRadius: '0px 0px 30px 30px',
    padding: '10px 18px',
    boxShadow: '0px 8px 35px rgba(6, 56, 63, 0.1)',
    marginBottom: 10
  },
}));
const APAVEIMAGE = require('assets/images/apave.png');
const AuthLayout = props => {
  const { route } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <main className={classes.content}>
        <Suspense fallback={<LinearProgress />}>
          <div className={classes.loginContainer}>
            <div className={classes.header}>
              <img src={APAVEIMAGE} alt="apave" className={classes.logo} />
              <div style={{ display: 'flex' }}>
                <SelectLanguage />
              </div>
            </div>
            {renderRoutes(route.routes)}
          </div>
        </Suspense>
      </main>
    </Fragment>
  );
};

AuthLayout.propTypes = {
  route: PropTypes.object,
};

export default AuthLayout;
