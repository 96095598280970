import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv/lib';
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export';
import { useDispatch } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import PageBase from 'components/PageBase';
import {
  makeStyles,
  Button,
  AppBar,
  IconButton,
  Tooltip,
  Grid,
  TextField,
  MenuItem,
  LinearProgress
} from '@material-ui/core';
import { store } from 'react-notifications-component';
import useRouter from 'utils/useRouter';
import {
  profileURL,
  LEAD_TYPES,
  EXPORT_MODE_EXCEL,
  EXPORT_MODE_CSV,
  LEAD_STATUSES,
  LEAD_EXTERNAL_STATUSES,
  LEAD_HUBSPOT_STATUSES,
  ROLE_CLIENT
} from 'config';
import * as API from 'services/api';
import {
  get10LastLetters,
  getHHMMDDMMYYYYFromTimeStamp,
} from 'utils/validations';
import InfiniteScroll from 'react-infinite-scroller';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import MUIDataTable from 'mui-datatables';
import EditIcon from '@material-ui/icons/Edit';
import RemoveUserModal from 'components/Modals/RemoveUserModal';
import CustomTable from 'components/CustomTable'
import SearchBar from 'components/CustomTable/searchbar'
import Filters from 'components/CustomTable/filters'
import { includesSearchWord } from 'utils'
import { TabPanel, StyledTabs, StyledTab } from 'components/CustomTab'
import ReplyIcon from '@material-ui/icons/Reply';
import DetailsModal from './message'
import useHomeContext from 'layouts/HomeLayout/context'
import FormDetails from './components/FormDetails'
import EyeIcon from '@material-ui/icons/Visibility'
import moment from 'moment';
import { RestoreOutlined } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 15,
    position: 'relative'
  },
  title: {
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 15,
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  avatar: {
    margin: 5,
    cursor: 'pointer',
  },
  icon: {
    fill: 'inherit !important'
  },
  password: {
    minWidth: 80,
  },
  actions: {
    minWidth: 80,
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  csv: {
    display: 'none',
  },
  excel: {
    marginRight: 20,
    backgroundColor: '#040f40',
    padding: 10,
    color: '#fff',
    borderRadius: 15,
    '&:hover': {
      backgroundColor: '#00B0EC',
    },
  },
  spinner: {
    display: 'block',
    margin: 'auto',
    marginTop: 20,
    marginBottom: 20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  rightdom: {
    marginLeft: 'auto'
  },
  table: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5)
  },
  appbar: {
    boxShadow: 'none',
    padding: theme.spacing(0, 5),
    backgroundColor: theme.palette.background.default,
  },
  tabsContainer: {
    color: theme.palette.app.primary
  },
  tabContainer: {
    maxWidth: 'unset'
  },
  outlined: {
    paddingTop: 11.5,
    paddingBottom: 11.5,
    width: 150
  },
  progress: {
    // position: 'absolute',
    // left: 0,
    // right: 0,
    // top: 0
  }
}));

const PERPAGE = 20;
let page = 1;

const Contacts = ({ intl: { formatMessage } }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [allUsersLoaded, setAllUsersLoaded] = useState(false);
  const csvLink = useRef();
  const excelExporter = useRef();

  const [loading, setLoading] = useState(true);
  const [openFilter, setOpenFilter] = useState(false)
  const [options, setOptions] = useState({})
  const [filters, setFilters] = useState({})
  const [keyword, setKeyword] = useState('')

  const [openModal, setOpenModal] = useState(false);
  const [detail_Id, setDetailId] = useState(null);

  const [openFormModal, setOpenFormModal] = useState();
  const [companies, setCompanies] = useState([]);

  const router = useRouter();
  const [removeModalInfo, setRemoveModalInfo] = useState({
    open: false,
    id: null,
  });

  const [teams, setTeams] = useState([]);
  const [tab, setTab] = useState("all");

  const [initLoad, setInitLoad] = useState(false);

  const handleTabChange = (event, newValue) => {
    setInitLoad(false)
    setTab(newValue)
  };

  const handleCloseRemoveModal = confirmed => {
    if (confirmed) {
      deleteUser(removeModalInfo.id);
    }
    setRemoveModalInfo({
      open: false,
      id: null,
    });
  };
  useEffect(() => {
    loadRecords(true);
  }, [tab, keyword]);
  useEffect(() => {
    API.listClients()
      .then(res => {
        if (res.code === 200) {
          setCompanies(res.data.filter(el => el.company).sort((a, b) => a.company.trim().localeCompare(b.company.trim())))
        }
      })
    API.listTeams({})
      .then(res => {
        if (res.code === 200) {
          setTeams(res.data);
        }
      })
      .catch(err => {

      });
  }, []);

  const loadRecords = (init) => {
    setLoading(true);
    setUsersLoading(true);
    page = init ? 1 : page + 1
    if (init) {
      setUsers([]);
      setAllUsersLoaded(false);
    }

    API.listLeads({
      page,
      perPage: PERPAGE,
      keyword,
      ...tab != 'all' ? { type: tab } : {}
    }).then(res => {
      if (!initLoad) setInitLoad(true)
      let news = res.data.map(el => mapData(el));
      setUsers(users => [...users, ...news]);
      setAllUsersLoaded(res.data.length < PERPAGE);
      setLoading(false);
      setUsersLoading(false);
    })
      .catch(err => {
        setLoading(false);
        setUsersLoading(false);
      });
  };

  const mapData = (el) => {
    const result = { ...el };
    if (!el.userId) {
      result.owner_name = `${el.source?.firstName || ""} ${el.source?.lastName || ""}`
    }

    result.typeName = tab == "gcorder" ? formatMessage({ id: `lead.type.gcorder` }) : formatMessage({ id: `lead.type.${el.type}` });
    switch (el?.type) {
      case "hubspotform":
        result.sourceContent = el.name;
        break;
      case LEAD_TYPES[0]: //'hubspot'
        result.sourceContent = `Ticket: ${el.source?.title || ""}`
        break;
      case LEAD_TYPES[1]: //'chat'
        result.sourceContent = (el.source?.description || "")
        break;
      case LEAD_TYPES[2]: //'userspaces'
        result.sourceContent = el.source?.productType == 'pack' ? formatMessage({ id: `home.catalogue.field.catalogueType.` + el.source?.productType }) : `Commande ${el.source?.title}`
        break;
      case LEAD_TYPES[3]: //'guestspaces'
        result.sourceContent = el.source?.productType == 'pack' ? formatMessage({ id: `home.catalogue.field.catalogueType.` + el.source?.productType }) : `Commande ${el.source?.title}`
        break;
      default:
        result.sourceContent = ""
        break;
    }

    if (tab == "userspaces" || tab == "gcorder") {
      try {
        const source = JSON.parse(el.source.data);
        result.training_center_name = source.training_center_name
        result.typeProduit = el.source.productType == "pack" ? "PACK" : (source.typeProduit || "").toUpperCase()
      } catch (err) {
        return el
      }
    }
    return result
  }
  const loadMore = () => {
    if (allUsersLoaded || usersLoading || !initLoad || tab == "hubspotform") {
      return;
    }
    setUsersLoading(true);
    setTimeout(() => {
      loadRecords();
    }, [500])
  };

  const exportExcel = () => {
    setData([]);
    loadExportData(1, EXPORT_MODE_EXCEL);
  };

  const exportCSV = () => {
    setData([]);
    loadExportData(1, EXPORT_MODE_CSV);
  };

  const loadExportData = (_page = 1, exportMode) => {
    setLoading(true);
    API.listLeads({ page: _page, perPage: 100, ...tab != 'all' ? { type: tab } : {} }).then(res => {
      if (res.code === 200) {
        let newUsers = res.data.map(el => mapData(el))

        setData(prev => [...prev, ...newUsers]);
        if (newUsers.length < 100) {
          setLoading(false);
          exportData(exportMode);
        } else {
          loadExportData(_page + 1, exportMode);
        }
      }
    });
  };
  const exportData = (exportMode) => {
    setTimeout(() => {
      if (exportMode === EXPORT_MODE_EXCEL) {
        excelExporter.current.save();
      } else {
        csvLink.current.link.click();
      }
    });
  };
  const onDeleteUser = id => {
    setRemoveModalInfo({
      open: true,
      id,
    });
  };

  const deleteUser = id => {
    setLoading(true);

    API.deleteLead(id)
      .then(res => {
        setLoading(false);
        if (res.code === 200) {
          store.addNotification({
            message: 'Removed successfully',
            type: 'success',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          setUsers(users => users.filter(e => e.id !== id));
        }
      })
      .catch(err => {
        setLoading(false);
      });
  };

  const tableColumns = tab == "intra" ? [
    {
      name: 'id',
      label: formatMessage({
        id: 'id',
      }),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return get10LastLetters(value)
        },
      },
    },
    {
      name: 'source.title',
      label: "Titre de la formation",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'source.traineeCount',
      label: "Nombre de stagiaire",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'source.orderDate',
      label: "Date souhaité",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return moment(value).format("DD/MM/YYYY")
        },
      },
    },
    {
      name: 'createdAt',
      label: formatMessage({
        id: 'created_at',
      }),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => getHHMMDDMMYYYYFromTimeStamp(value),
      },
    },
  ] : [
    {
      name: 'id',
      label: formatMessage({
        id: 'id',
      }),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return get10LastLetters(value)
        },
      },
    },
    {
      name: 'typeName',
      label: "Type",
      options: {
        filter: false,
        sort: true,
        // customBodyRender: value => formatMessage({ id: 'lead.type.' + value }),
        // customFilterList: LEAD_TYPES.map(item => ({ value: item, label: formatMessage({ id: 'lead.type.' + item }) })),
        // allText: formatMessage({ id: 'lead.type.all' })
      },
    },
    ...tab != LEAD_TYPES[0] && tab != "hubspotform" && (tab != "userspaces" && tab != "gcorder") ? [
      {
        name: 'owner_name',
        label: formatMessage({
          id: 'user',
        }),
        options: {
          filter: true,
          sort: true
        },
      },
      {
        name: 'teamIds',
        label: formatMessage({
          id: 'navbar.users.internal.teams',
        }),
        options: {
          filter: false,
          sort: false,
          customBodyRender: value => {
            return value && value.length > 0 ? value.map(item => teams.find(el => el.id == item)).filter(item => item).map(item => item.title).join(', ') : ''
          }
        },
      },
    ] : [],
    ...(tab == "userspaces" || tab == "gcorder") ? [
      {
        name: 'owner_name',
        label: formatMessage({
          id: 'user',
        }),
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'typeProduit',
        label: formatMessage({
          id: 'leads.table.producttype',
        }),
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'training_center_name',
        label: formatMessage({
          id: 'leads.table.trainingcenter',
        }),
        options: {
          filter: false,
          sort: true,
        },
      },
    ] : [],
    {
      name: 'sourceContent',
      label: "Source",
      options: {
        filter: false,
        sort: false,
      },
    },
    ...tab != "hubspotform" ? [
      {
        name: 'status',
        label: formatMessage({
          id: 'statusInternal',
        }),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            const record = users.find(item => item.id == tableMeta.rowData[0])
            return <TextField
              select
              variant="outlined"
              value={value}
              onChange={e => {
                setLoading(true)
                API.updateLead(tableMeta.rowData[0], { status: e.target.value, hubspot: record.type == LEAD_TYPES[0] }).then(res => {
                  if (res.code === 200) {
                    setUsers(prev => prev.map(item => item.id == tableMeta.rowData[0] ? { ...item, status: e.target.value } : item));
                  }
                  setLoading(false)
                }).catch(err => {
                  setLoading(false)
                });
              }}
              style={{ minWidth: 160 }}
            >
              {
                record.type == LEAD_TYPES[0] ? LEAD_HUBSPOT_STATUSES.map(item => {
                  return <MenuItem key={item} value={item}>
                    {formatMessage({ id: 'leads.form.hubspotstatus.' + item })}
                  </MenuItem>
                }) :
                  LEAD_STATUSES.map(item => {
                    return <MenuItem key={item} value={item}>
                      {formatMessage({ id: 'leads.status.' + item })}
                    </MenuItem>
                  })
              }
            </TextField>
          }
        },
      },
    ] : [],
    ...tab != LEAD_TYPES[0] && tab != "hubspotform" ? [
      {
        name: 'statusExternal',
        label: formatMessage({
          id: 'statusExternal',
        }),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            const record = users.find(item => item.id == tableMeta.rowData[0])
            if (record.type == LEAD_TYPES[0]) return null
            return <TextField
              select
              variant="outlined"
              value={value || LEAD_EXTERNAL_STATUSES[0]}
              onChange={e => {
                setLoading(true)
                API.updateLead(tableMeta.rowData[0], { statusExternal: e.target.value }).then(res => {
                  if (res.code === 200) {
                    setUsers(prev => prev.map(item => item.id == tableMeta.rowData[0] ? { ...item, statusExternal: e.target.value } : item));
                  }
                  setLoading(false)
                }).catch(err => {
                  setLoading(false)
                });
              }}
              style={{ minWidth: 160 }}
              disabled={record?.type == LEAD_TYPES[0]}
            >
              {
                LEAD_EXTERNAL_STATUSES.map(item => {
                  return <MenuItem key={item} value={item}>
                    {formatMessage({ id: 'leads.status.' + item })}
                  </MenuItem>
                })
              }
            </TextField>
          }
        },
      },
      {
        name: 'reference',
        label: formatMessage({
          id: 'leads.table.reference',
        }),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            const record = users.find(item => item.id == tableMeta.rowData[0])
            if (record.type == LEAD_TYPES[0]) return null
            return <TextField
              select
              variant="outlined"
              value={value}
              onChange={e => {
                setLoading(true)
                API.updateLead(tableMeta.rowData[0], { reference: e.target.value }).then(res => {
                  if (res.code === 200) {
                    setUsers(prev => prev.map(item => item.id == tableMeta.rowData[0] ? { ...item, reference: e.target.value } : item));
                  }
                  setLoading(false)
                }).catch(err => {
                  setLoading(false)
                });
              }}
              style={{ minWidth: 160 }}
              disabled={record?.type == LEAD_TYPES[0]}
            >
              {
                ["bb", "pk", "cv"].map(item => {
                  return <MenuItem key={item} value={item}>
                    {formatMessage({ id: 'leads.table.reference.' + item })}
                  </MenuItem>
                })
              }
            </TextField>
          }
        },
      },
    ] : [],
    {
      name: 'createdAt',
      label: formatMessage({
        id: 'created_at',
      }),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => getHHMMDDMMYYYYFromTimeStamp(value),
      },
    },
    {
      name: 'actions',
      label: formatMessage({
        id: 'actions',
      }),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const record = users.find(item => item.id == tableMeta.rowData[0])
          return (<React.Fragment>
            {
              record?.type == LEAD_TYPES[1] &&
              <Tooltip title={"Reply"}>
                <IconButton onClick={() => {
                  setDetailId(record); // id
                  setOpenModal(true)
                }}>
                  <ReplyIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            }
            {
              record?.type != LEAD_TYPES[1] &&
              <Tooltip title={"Details"}>
                <IconButton onClick={() => {
                  setOpenFormModal(record)
                }}>
                  <EyeIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            }
            {
              record?.type != LEAD_TYPES[0] && record?.type != "hubspotform" &&
              <Tooltip title={formatMessage({ id: 'tooltip.delete' })}>
                <IconButton onClick={() => {
                  onDeleteUser(tableMeta.rowData[0]); // id
                }}>
                  <DeleteIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            }
          </React.Fragment>
          )
        },
      },
    },
  ];
  const excelData = data.map(user => {
    return {
      ...user,
      id: get10LastLetters(user.id),
      type: formatMessage({ id: 'lead.type.' + user.type }),
      status: user.type == LEAD_TYPES[0] ? formatMessage({ id: 'leads.form.hubspotstatus.' + user.status }) : formatMessage({ id: 'leads.status.' + user.status }),
      statusExternal: formatMessage({ id: 'leads.status.' + user.statusExternal }),
      createdAt: getHHMMDDMMYYYYFromTimeStamp(user.createdAt),
      ...tab == "intra"? {
        title: user.source.title,
        traineeCount: user.source.traineeCount,
        orderDate: moment(user.source.orderDate).format("DD/MM/YYYY")
      }: {}
    }
  });
  const headers = tab == "intra" ?[
    { label: formatMessage({ id: 'no' }), key: 'id' },
    { label: "Titre de la formation", key: 'title' },
    { label: "Nombre de stagiaire", key: 'traineeCount' },
    { label: "Date souhaité", key: 'orderDate' },
    {
      key: 'createdAt',
      label: formatMessage({
        id: 'created_at',
      })
    }
  ]:[
    { label: formatMessage({ id: 'no' }), key: 'id' },
    { label: "Type", key: 'typeName' },
    ...tab != LEAD_TYPES[0] && tab != "hubspotform" && (tab != "userspaces" && tab != "gcorder") ? [
      {
        key: 'owner_name',
        label: formatMessage({
          id: 'user',
        }),
      },
      {
        key: 'teamIds',
        label: formatMessage({
          id: 'navbar.users.internal.teams',
        }),
      },
    ] : [],
    ...(tab == "userspaces" || tab == "gcorder") ? [
      {
        key: 'owner_name',
        label: formatMessage({
          id: 'user',
        }),
      },
      {
        key: 'typeProduit',
        label: formatMessage({
          id: 'leads.table.producttype',
        }),
      },
      {
        key: 'training_center_name',
        label: formatMessage({
          id: 'leads.table.trainingcenter',
        }),
      },
    ] : [],
    {
      key: 'sourceContent',
      label: "Source",
    },
    ...tab != "hubspotform" ? [
      {
        key: 'status',
        label: formatMessage({
          id: 'statusInternal',
        })
      },
    ] : [],
    ...tab != LEAD_TYPES[0] && tab != "hubspotform" ? [
      {
        key: 'statusExternal',
        label: formatMessage({
          id: 'statusExternal',
        }),
      },
    ] : [],
    {
      key: 'createdAt',
      label: formatMessage({
        id: 'created_at',
      })
    }
  ];

  let datasource = users

  return (
    <PageBase title={formatMessage({ id: 'home.contacts.title' })}
      rightComponent={<div className={classes.rightdom}>
        <Button onClick={exportExcel} classes={{ root: classes.excel }}>
          <FormattedMessage id="export_xls" />
        </Button>
        <Button onClick={exportCSV} classes={{ root: classes.excel }}>
          <FormattedMessage id="export_csv" />
        </Button>
        <CSVLink
          data={excelData}
          headers={headers}
          className={classes.csv}
          filename="leads.csv"
          ref={csvLink}
        >
          CSV
        </CSVLink>
      </div>}
      loading={loading}
    >
      <AppBar position="static" className={classes.appbar}>
        <StyledTabs value={tab} onChange={handleTabChange} aria-label="services tabs" className={classes.tabsContainer}>
          <StyledTab key="all" value="all" className={classes.tabContainer} label={<FormattedMessage id="lead.type.all" />} />
          <StyledTab key="chat" value="chat" className={classes.tabContainer} label={<FormattedMessage id={"lead.type.chat"} />} />
          <StyledTab key="userspaces" value="userspaces" className={classes.tabContainer} label={<FormattedMessage id={"lead.type.userspaces"} />} />
          <StyledTab key="gcorder" value="gcorder" className={classes.tabContainer} label={<FormattedMessage id={"lead.type.gcorder"} />} />
          <StyledTab key="question" value="question" className={classes.tabContainer} label={<FormattedMessage id={"lead.type.question"} />} />
          <StyledTab key="intra" value="intra" className={classes.tabContainer} label={<FormattedMessage id={"Demande Intra"} />} />
        </StyledTabs >
      </AppBar>
      <div>
        <Grid container >
          <Grid item sm={openFilter ? 9 : 12} style={{ padding: '0px !important' }}>
            <SearchBar
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              keyword={keyword}
              setKeyword={setKeyword}
            />
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={!allUsersLoaded}
              className={classes.table}
            >
              <CustomTable
                columns={tableColumns}
                data={datasource}
                options={{
                  pagination: false,
                  print: false,
                  download: false,
                  selectableRows: 'none',
                  searchable: false,
                  viewColumns: false,
                  elevation: 0,
                  filter: false,
                  search: false,
                  textLabels: {
                    body: {
                      noMatch: formatMessage({
                        id: 'muitable.placeholder.nomatch_records',
                      }),
                    },
                    filter: {
                      all: formatMessage({ id: 'all' }),
                      title: formatMessage({ id: 'filters' }),
                      reset: formatMessage({ id: 'reset' }),
                    },
                  },
                }}
              />
            </InfiniteScroll>
          </Grid>
          {/* {
            openFilter &&
            <Filters
              setOpenFilter={setOpenFilter}
              setOnFilter={setFilters}
              columns={tableColumns}
              data={users}
            />
          } */}
        </Grid>
        <RemoveUserModal
          open={removeModalInfo.open}
          description={formatMessage({
            id: 'comments.please_confirm_again_to_delete_user',
          })}
          onCloseModal={handleCloseRemoveModal}
        />
      </div>
      <DetailsModal
        formatMessage={formatMessage}
        requestId={detail_Id}
        open={openModal}
        handleClose={(refresh) => {
          setOpenModal(false)
        }}
      />
      {
        openFormModal &&
        <FormDetails
          formatMessage={formatMessage}
          data={openFormModal}
          open={!!openFormModal}
          handleClose={(refresh) => {
            setOpenFormModal()
          }}
          companies={companies}
        />
      }
      <ExcelExport
        data={excelData}
        fileName="leads.xlsx"
        ref={excelExporter}
      >
        {
          headers.map(item =>
            <ExcelExportColumn
              key={item.key}
              field={item.key}
              title={item.label}
              width={100}
              cellOptions={{ fontSize: 12 }}
              headerCellOptions={{ fontSize: 14 }}
            />)
        }
      </ExcelExport>
    </PageBase>
  );
};

Contacts.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};

export default injectIntl(Contacts);
