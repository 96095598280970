import {
    Card,
    CardContent,
    Typography,
    TextField,
    Chip,
    ListItem,
    ListItemText
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ModalWrap from 'components/Modals/ModalWrap';
import { makeStyles } from '@material-ui/core/styles';
import useRouter from 'utils/useRouter';
import * as API from 'services/api';
import CustomTextInput from 'components/CustomTextInput';
import {
    ConversationMessages,
    ConversationForm
} from './components';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/actions/app';
import { LEAD_TYPES } from 'config';

const useStyles = makeStyles(theme => ({
    root: {},
    card: {
        overflow: 'visible'
    },
    field: {
        marginBottom: theme.spacing(2)
    },
    groupTitle: {
        color: '#868aa8',
        fontWeight: 'bold'
    },
    subContent: {
        pointerEvents: 'none'
    },
    label: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0.5)
    }
}));

const LocationDetails = ({ formatMessage, requestId, open, handleClose }) => {
    const router = useRouter();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [messages, setMessages] = useState([])
    useEffect(() => {
        if (open && requestId) {
            loadMessages()
        }
    }, [open])
    const loadMessages = () => {
        setMessages([])
        dispatch(setLoading(true));
        API.listMessages({ requestId: requestId.id }).then(res => {
            dispatch(setLoading(false));
            if (res.code == 200) {
                setMessages(res.data.reverse())
            }
        })
    }

    let title = formatMessage({ id: "leads.messages" });
    if (requestId?.type == LEAD_TYPES[4]) {
        title = requestId?.source?.title;
    }
    return (
        <ModalWrap
            title={title}
            open={open}
            closeModal={handleClose}
        >
            <ConversationMessages messages={messages} />
            <ConversationForm requestId={requestId} loadMessages={loadMessages} />
        </ModalWrap>
    );
};

LocationDetails.propTypes = {};

export default LocationDetails;

const Category = ({ id }) => {

    return (<Chip label={''} />);
};
