import React from 'react';
import FileDownloadIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    messageContainer: {
        display: 'flex',
        padding: '0.9em 1.5em',
        margin: '0.6em 0',
        border: '2px solid #e4e7e9',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    mediaInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: 0,
        '& p': {
            margin: 0,
            fontSize: '12px',
        },
    },
    filename: {
        fontWeight: 700,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    size: {
        fontWeight: 400,
    },
    image: {
        width: 150,
        height: 150,
        marginBottom: 8
    }
});

export function formatFileSize(bytes, suffixIndex = 0) {
    const suffixes = ['bytes', 'KB', 'MB', 'GB'];
    if (bytes < 1000) return +bytes.toFixed(2) + ' ' + suffixes[suffixIndex];
    return formatFileSize(bytes / 1024, suffixIndex + 1);
}

export default function MediaMessage({ media }) {
    const classes = useStyles();

    const handleClick = () => {
        const anchorEl = document.createElement('a');

        anchorEl.href = media.url;
        anchorEl.target = '_blank';
        anchorEl.rel = 'noopener';

        // setTimeout is needed in order to open files in iOS Safari.
        setTimeout(() => {
            anchorEl.click();
        });
    };
    const splits = media.type && media.type.split('/')
    const type = splits && splits.length > 0 ? splits[0] : null
    
    return (
        <div className={classes.messageContainer} onClick={handleClick}>
            {/* <div className={classes.iconContainer}>
                <FileDownloadIcon />
            </div> */}
            <div className={classes.mediaInfo}>
                {
                    type == 'image' ?
                        <img
                            alt="Attachment"
                            className={classes.image}
                            src={media.url}
                        /> :
                        (
                            type == 'video' ?
                                <video className={classes.image} controls src={media.url} />
                                :
                                <p className={classes.filename}>{media.filename}</p>)
                }
                <p className={classes.size}>{formatFileSize(media.size)} - Click to open</p>
            </div>
        </div>
    );
}
