import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import WarningIcon from '@material-ui/icons/Warning';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  CardActions,
  Button,
} from '@material-ui/core';
import { injectIntl, FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    minWidth: 100,
    backgroundColor: '#040f40',
    padding: 10,
    color: '#fff',
    borderRadius: 15,
    '&:hover': {
      backgroundColor: '#00B0EC',
    },
  },
  header: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
  },
}));

const RemoveUserModal = ({ open, description, onCloseModal }) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => {
        onCloseModal(false);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card style={{ width: 400 }}>
          <CardHeader
            component={() => (
              <div className={classes.header}>
                <WarningIcon />&nbsp;
                <Typography><FormattedMessage id="alert" /></Typography>
              </div>
            )}
          />
          <Divider />
          <CardContent>
            <Typography>{description}</Typography>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              onClick={() => {
                onCloseModal(true);
              }}
              classes={{ root: classes.button }}
            >
              OK
            </Button>
            <Button
              onClick={() => {
                onCloseModal(false);
              }}
              classes={{ root: classes.button }}
            >
              <FormattedMessage id="cancel" />
            </Button>
          </CardActions>
        </Card>
      </Fade>
    </Modal>
  );
};

RemoveUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default RemoveUserModal;
