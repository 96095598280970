import * as actionTypes from '../constants';

const initialState = {
  loading: false,
  refreshed: false,
  imageModalVisible: false,
  imageModalSrc: '',
  profile: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTION_UPDATE_APP:
      return { ...state, ...action.payload };
    case actionTypes.ACTION_SET_LOADING:
      return { ...state, loading: action.payload };
    case actionTypes.ACTION_SHOW_IMAGE_MODAL:
      return {
        ...state,
        imageModalVisible: true,
        imageModalSrc: action.payload,
      };
    case actionTypes.ACTION_INIT_APP:
      return initialState;
    default:
      return state;
  }
};
