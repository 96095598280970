import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    messageContainer: {
        display: 'flex',
        padding: '0.9em 1.5em',
        margin: '0.6em 0',
        border: '2px solid #e4e7e9',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    mediaInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: 0,
        '& p': {
            margin: 0,
            fontSize: '12px',
        },
    },
    trigram: {
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: `15px !important`,
        textAlign: 'left'
    },
    title: {
        fontWeight: 700,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: `25px !important`
    },
    size: {
        fontWeight: 400,
    },
    image: {
        width: 150,
        height: 150,
        marginBottom: 8
    }
});

export default function AssetMessage({ asset, formatMessage }) {
    const classes = useStyles();
    const handleClick = () => {
        const win = window.open(asset.link, "_blank");
        win.focus();
    };

    return (
        <div className={classes.messageContainer} onClick={handleClick}>
            <div className={classes.mediaInfo}>
                <p className={classes.trigram}>{asset.trigram}</p>
                <p className={classes.title}>{asset.title}</p>
                <p className={classes.size}>{formatMessage({ id: asset.type })} - Click to open</p>
            </div>
        </div>
    );
}
