import { put, call, select } from 'redux-saga/effects';
import { updateAuth, initAuth } from 'redux/actions/auth';
import { isEmail } from 'utils/validations';
import * as APIs from 'services/api';
import { initApp, setLoading } from 'redux/actions/app';

export function* login(action) {
  const { payload: { email, password, code, callback } } = action;
  yield put(
    updateAuth({
      errorMessage: '',
    }),
  );

  if (email === '' || password === '') {
    return yield put(
      updateAuth({
        errorMessage: 'Please input email and password',
      }),
    );
  }
  if (!isEmail(email)) {
    return yield put(
      updateAuth({
        errorMessage: 'Please input a valid email',
      }),
    );
  }

  try {
    yield put(setLoading(true));
    const res = yield call(APIs.signin, {
      email, password, code
    });
    yield put(setLoading(false));
    if (res.code === 200) {
      const { token } = res;
      if (token) {
        yield put(
          updateAuth({
            ...token,
            loggedIn: true,
            email: String(email).toLowerCase().trim(),
            errorMessage: '',
          }),
        );
      } else {
        callback && callback(true)
      }
    } else {
      yield put(
        updateAuth({
          errorMessage: res.message ? res.message : 'Wrong user or password1, please try again.',
        }),
      );
    }
  } catch (error) {
    yield put(setLoading(false));
    yield put(
      updateAuth({
        errorMessage: 'Wrong user or password2, please try again.',
      }),
    );
  }
}

export function* logout() {
  const { email, refreshToken, accessToken } = yield select(state => state.auth);
  yield put(setLoading(true));
  try {
    yield call(APIs.signout, { email, refreshToken, accessToken });
    yield put(setLoading(false));
    yield put(initAuth());
    yield put(initApp());
  } catch (error) {
    yield put(setLoading(false));
    yield put(initAuth());
    yield put(initApp());
  }
}
