import React, { Suspense, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  appRefreshedSelector,
  loggedInSelector,
  appLoadingSelector,
} from 'redux/selectors';

import { TopBar, NavBar } from './components';
import { refreshApp } from 'redux/actions/app';
import Loader from 'components/Loader';
import { drawerWidth, drawerCloseWidth } from './components/NavBar'
import { topbar_height } from './components/TopBar'
import { HomeProvider } from './context'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },

  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    overflowY: 'hidden',
    flex: '1 1 auto',
    marginLeft: drawerWidth,
    marginTop: topbar_height,
    transition: 'all .5s ease',
  },
}));

const HomeLayout = props => {
  const { route } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(loggedInSelector);
  const isRefreshed = useSelector(appRefreshedSelector);
  const isLoading = useSelector(appLoadingSelector);
  const [openDrawer, setOpenDrawer] = useState(false)


  if (!isLoggedIn) {
    return <Redirect to="/auth/login" />;
  }

  if (!isRefreshed) {
    dispatch(refreshApp());
    return <Loader />;
  }

  return (
    <HomeProvider>
      <div className={classes.root}>
        <TopBar openDrawer={openDrawer} />
        <div className={classes.container}>
          <NavBar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
          <main className={classes.content} style={!openDrawer ? { marginLeft: drawerCloseWidth } : {}}>
            <Suspense fallback={<LinearProgress />}>
              {renderRoutes(route.routes)}
            </Suspense>
          </main>
          {isLoading && <Loader />}
        </div>
      </div>
    </HomeProvider>
  );
};

HomeLayout.propTypes = {
  route: PropTypes.object,
};

export default HomeLayout;
