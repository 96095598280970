import axios from 'axios';
import { updateAuth } from 'redux/actions/auth';
import { apiURL } from 'config';
import { store } from 'App';

axios.defaults.baseURL = apiURL;

// Add a request interceptor
axios.interceptors.request.use(
  config => {
    const { tokenType, accessToken, loggedIn } = store.getState().auth;
    const token = `${tokenType} ${accessToken}`;
    if (loggedIn) {
      config.headers['Authorization'] = token;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

//Add a response interceptor

axios.interceptors.response.use(
  response => {
    return response;
  },
  function(error) {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      (originalRequest.url === `/auth/admin-signin` ||
        originalRequest.url === `/auth/admin-refresh-token`)
    ) {
      // router.history.push('/auth/login');
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const { refreshToken, email } = store.getState().auth;
      return axios
        .post('/auth/admin-refresh-token', {
          email,
          refreshToken,
        })
        .then(response => {
          const { data: res } = response;
          if (res.code === 200) {
            store.dispatch(updateAuth({ ...res.token }));
            const { tokenType, accessToken } = res.token;
            axios.defaults.headers.common[
              'Authorization'
            ] = `${tokenType} ${accessToken}`;
            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  },
);

export default axios;
