import { combineReducers } from 'redux';
import app from './app';
import auth from './auth';
import language from './language';

export default combineReducers({
  app,
  auth,
  language,
});
