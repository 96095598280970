// auth
export const authSelector = state => state.auth;
export const loggedInSelector = state => authSelector(state).loggedIn;

// language
export const localeSelector = state => state.language.locale;

// app
export const appSelector = state => state.app;
export const appProfileSelector = state => appSelector(state).profile;
export const appRefreshedSelector = state => appSelector(state).refreshed;
export const appLoadingSelector = state => appSelector(state).loading;

export const appProfileRoleSelector = state => appProfileSelector(state).role;
