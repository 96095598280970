import React, { useState } from 'react';
import {
  Drawer,
  makeStyles,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Grid
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { appProfileSelector } from 'redux/selectors';
import classNames from 'classnames';
import GetMenus from './menus';
import useRouter from 'utils/useRouter';
import { logout } from 'redux/actions/auth';
import useHomeContext from 'layouts/HomeLayout/context'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const APAVEIMAGE = require('assets/images/apave.png');
export const drawerWidth = 230;
export const drawerCloseWidth = 60;

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: drawerWidth,
    border: 'none',
    //borderTopRightRadius: theme.spacing(4),
    //borderBottomRightRadius: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      border: 'none',
      position: 'relative',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    backgroundColor: theme.palette.app.primary,
    transition: 'all .5s ease',
  },
  drawerClosePaper: {
    width: drawerCloseWidth,
    border: 'none',
    [theme.breakpoints.up('md')]: {
      width: drawerCloseWidth,
      border: 'none',
      position: 'relative',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    backgroundColor: theme.palette.app.primary,
    transition: 'all .5s ease',
  },
  drawerContainer: {
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 1200,
  },
  drawerInner: {
    width: drawerWidth,
    height: '100%',
  },
  menuList: {
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxHeight: 800,
    minHeight: 500,
  },
  link: {
    textDecoration: 'none',
    background: 'none',
    border: 'none',
    width: '100%',
    padding: 0,
    cursor: 'pointer',
    color: theme.palette.app.primary,
    wordBreak: 'break-all',
  },
  logo: {
    width: 160,
    backgroundColor: 'white',
    borderRadius: '0px 0px 30px 30px',
    padding: '16px 24px'
  },
  activebar: {
    position: 'absolute',
    left: 0,
    width: 3,
    top: 0,
    bottom: 0,
    zIndex: 1,
    backgroundColor: 'white'
  },
  closeSideMenuItem: {
    paddingLeft: '21px !important'
  },
  submenuitem: {
    paddingTop: '4px !important',
    paddingBottom: '4px !important',
  }
}));

const NavBar = ({ openDrawer, setOpenDrawer }) => {
  const classes = useStyles();
  const { role } = useSelector(appProfileSelector);
  const { hasPermission } = useHomeContext();

  const menus = GetMenus(role, hasPermission);

  const router = useRouter();
  const pathname = String(router.history.location.pathname).toLowerCase();
  const dispatch = useDispatch();

  const isActive = item => {
    return !item.children ? pathname == item.url : pathname.includes(String(item.url).toLowerCase());
  };
  const onSignOut = () => {
    dispatch(logout());
  };
  const openNewUrl = (link) => {
    const win = window.open(link, "_blank");
    win.focus();
  }
  return (
    <Drawer
      id="sidebar-menu"
      anchor="left"
      open={true}
      classes={{ paper: openDrawer ? classes.drawerPaper : classes.drawerClosePaper, root: classes.drawerContainer }}
      ModalProps={{
        keepMounted: true,
      }}
      variant="permanent"
      onMouseOver={(e) => {
        e.preventDefault();
        setOpenDrawer(true)
      }}
      onMouseLeave={(e) => {
        e.preventDefault();
        setOpenDrawer(false)
      }}
    >
      <div className={classes.drawerInner}>
        <div className={classes.menuList}>
          {
            openDrawer ?
              <Grid container justify="center">
                <img src={APAVEIMAGE} alt="apave" className={classes.logo} />
              </Grid> :
              <div style={{ height: 75 }} />
          }
          <MenuList>
            {menus.menus.map((item, index) => <SubMenu
              key={item.id}
              item={item}
              openNewUrl={openNewUrl}
              isActive={isActive}
              openDrawer={openDrawer}
            />)}
          </MenuList>
          <MenuList>
            {menus.menus_bottom.map((item, index) => (
              <div
                key={`id-${item.id}`}
                className={classNames(classes.link, 'side_menu-item')}
                onClick={() => {
                  if (item.href) {
                    openNewUrl(item.href)
                    return
                  }
                  if (item.id === 'exit') {
                    return onSignOut();
                  }
                  router.history.push(item.url);
                }}
                role="button"
                tabIndex={index}
              >
                {
                  isActive(item) && <div className={classes.activebar} />
                }
                <MenuItem
                  className={classNames(isActive(item) ? 'menu-item--selected' : '', { [classes.closeSideMenuItem]: !openDrawer })}
                >
                  <ListItemIcon className={'menu-icon'}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    inset
                    disableTypography
                    className={classNames(
                      'truncate-list-item-text',
                      'menu-text',
                    )}
                  />
                </MenuItem>
              </div>
            ))}
          </MenuList>
        </div>
      </div>
    </Drawer>
  );
};

export default NavBar;

const SubMenu = ({ item, openNewUrl, isActive, openDrawer }) => {
  const classes = useStyles();
  const router = useRouter();
  const [expand, setExpand] = useState(isActive(item))
  return <div>
    <div
      key={`id-${item.id}`}
      className={classNames(classes.link, 'side_menu-item')}
      onClick={() => {
        if (item.href) {
          openNewUrl(item.href)
          return
        }
        if (item.id == 'apidoc') {
          window.open("https://console.monespace.apavesuite.com/doc/API%20Espace%20Client%20Apave%20V2.0.1.html", '_blank')
          return;
        }
        router.history.push(item.url);
      }}
      role="button"
    >
      {
        isActive(item) && <div className={classes.activebar} />
      }
      <MenuItem
        className={classNames(classes.menuitem, isActive(item) ? 'menu-item--selected' : '', { [classes.closeSideMenuItem]: !openDrawer })}
      >
        <ListItemIcon className={'menu-icon'}>
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.text}
          inset
          disableTypography
          className={classNames(
            'truncate-list-item-text',
            'menu-text',
          )}
        />
        {
          item.children &&
          <>
            {
              expand ?
                <ExpandLessIcon style={{ fill: "white" }} onClick={e => {
                  e.stopPropagation();
                  setExpand(false)
                }} /> :
                <ExpandMoreIcon style={{ fill: "white" }} onClick={e => {
                  e.stopPropagation();
                  setExpand(true)
                }} />
            }
          </>
        }
      </MenuItem>
    </div>
    {
      expand && item.children && openDrawer &&
      <div>
        {
          item.children.map(el => {
            return <CollapseMenu
              key={el.id}
              item={el}
              openNewUrl={openNewUrl}
              isActive={isActive}
              openDrawer={openDrawer}
            />
          })
        }
      </div>
    }
  </div>
}

const CollapseMenu = ({ item, openNewUrl, isActive, openDrawer }) => {
  const classes = useStyles();
  const router = useRouter();
  const [expand, setExpand] = useState(isActive(item))
  return <div>
    <div
      key={`id-${item.id}`}
      className={classNames(classes.link, 'side_menu-item')}
      onClick={() => {
        if (item.href) {
          openNewUrl(item.href)
          return
        }
        router.history.push(item.url);
      }}
      role="button"
    >
      <MenuItem
        className={classNames(classes.menuitem, isActive(item) ? 'submenu-item--selected' : '', { [classes.closeSideMenuItem]: !openDrawer })}
      >
        <ListItemIcon className={'menu-icon'}>
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.text}
          inset
          disableTypography
          className={classNames(
            'truncate-list-item-text',
            'sub-menu-text',
          )}
        />
        {
          item.children &&
          <>
            {
              expand ?
                <ExpandLessIcon style={{ fill: "white" }} onClick={e => {
                  e.stopPropagation();
                  setExpand(false)
                }} /> :
                <ExpandMoreIcon style={{ fill: "white" }} onClick={e => {
                  e.stopPropagation();
                  setExpand(true)
                }} />
            }
          </>
        }
      </MenuItem>
    </div>
    {
      expand && item.children && openDrawer &&
      <div style={{ borderTop: "1px solid #1A3566", borderBottom: "1px solid #1A3566" }}>
        {
          item.children.map(el => {
            return <div
              key={`id-${el.id}`}
              className={classNames(classes.link, 'side_menu-item')}
              onClick={() => {
                if (el.href) {
                  openNewUrl(el.href)
                  return
                }
                router.history.push(el.url);
              }}
            >
              <MenuItem
                className={classNames(classes.submenuitem, isActive(el) ? 'submenu-item--selected' : '', { [classes.closeSideMenuItem]: !openDrawer })}
              >
                <ListItemText
                  primary={el.text}
                  inset
                  disableTypography
                  className={classNames(
                    'truncate-list-item-text',
                    'collapse-menu-text',
                  )}
                />
              </MenuItem>
            </div>
          })
        }
      </div>
    }
  </div>
}
