import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import configureStore from 'redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { translationMessages } from 'i18n';
import LanguageProvider from 'layouts/LanguageProvider';
import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';
import { renderRoutes } from 'react-router-config';
import routes from 'routes';
import themeDefault from 'theme';
import GlobalStyle from 'global-styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const history = createBrowserHistory();
export const { store, persistor } = configureStore({}, history);

function App() {
  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={themeDefault}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <LanguageProvider messages={translationMessages}>
              <Router history={history}>
                <ReactNotification />
                {renderRoutes(routes)}
                <GlobalStyle />
              </Router>
            </LanguageProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </PersistGate>
    </StoreProvider>
  );
}

export default App;
