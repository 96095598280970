import { postData, getData, deleteData, patchData } from './base';
import { userspaceURL } from 'config';

const apiEndPoints = {
  // auth
  login: 'auth/admin-signin',
  refreshToken: 'auth/admin-refresh-token',
  signout: 'auth/sign-out',
  // users
  listUsers: 'users',
  userProfile: 'users/profile',
  changePassword: 'users/change-password',
  mobility: 'users/mobilityuser',
  apaveusers: 'users/apaveusers',
  // apps
  apaveApps: '/apave-apps',
  permissions: '/permissions',
  // admin
  profile: 'admins/profile',
  clients: 'admins/clients/',
  managers: 'admins/managers/',
  createClient: 'admins/clients',
  users: 'admins/users',
  getSuggestions: 'admins/suggestions',
  generalInformation: 'admins/general-info',
  importUsers: 'admins/imports',
  importEstablishment: 'admins/import_establishment',
  importExclusion: 'admins/import_exclusion',
  listCompanies: 'companies',
  groups: 'groups',

  teams: 'admins/teams',

  request: '/request',
  //product
  catalogue: '/catalogue/',
  product: '/product_national/',
  location: '/location',
  training: '/training',
  sites: '/sites',
  sitesubmission: '/leads/forms',

  leads: '/leads',
  trainingproducts: "/products",

  resetPasswordWithToken: '/apave-auth/reset-password-with-token',
  sendResetPasswordLink: '/apave-auth/send-reset-password-link',
  sendVerificationEmail: '/apave-auth/send-verification-email',
  verifyEmail: '/apave-auth/verify-email',
  verifyResetPasswordLink: '/apave-auth/verify-reset-password-link',

  logs: "/logs",
  sessions: "/sessions",
  formation: `${userspaceURL}trainees`, // userspace trainings
  documents: `${userspaceURL}documents`, // userspace documents
  codeproducts: "/codeproducts",
  submissions: "/submissions",
  compteSel: "/compteSel",
  notifications: "notifications",
  caracteristiques: "/caracteristiques"
};

const headers = {
  'Content-Type': 'application/json',
};

export const signin = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.login, body, headers)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const refreshTokenAPI = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.refreshToken, body, headers)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const signout = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.signout, body, headers)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const changePassword = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.changePassword, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const getMobilityUser = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.mobility, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const listUsers = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.listUsers}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const listCompanies = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.listCompanies}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const deleteCompany = id =>
  new Promise((resolve, reject) => {
    deleteData(`${apiEndPoints.listCompanies}/${id}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const updateProfile = body =>
  new Promise((resolve, reject) => {
    patchData(apiEndPoints.userProfile, body, {
      'Content-Type': 'multipart/form-data',
    })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const getProfile = () =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.profile)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

// user
export const createUser = data =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.users, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const getUser = id =>
  new Promise((resolve, reject) => {
    getData(`${apiEndPoints.users}/${id}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const updateUser = (id, data) =>
  new Promise((resolve, reject) => {
    patchData(`${apiEndPoints.users}/${id}`, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const deleteUser = id =>
  new Promise((resolve, reject) => {
    deleteData(`${apiEndPoints.listUsers}/${id}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

// client
export const createClient = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.createClient, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const getClient = id =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.clients + id)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const updateClient = (id, data) =>
  new Promise((resolve, reject) => {
    patchData(apiEndPoints.clients + id, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const listClients = () =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.clients)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

// managers
export const createManager = data =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.managers, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const getManager = id =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.managers + id)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const updateManager = (id, data) =>
  new Promise((resolve, reject) => {
    patchData(apiEndPoints.managers + id, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
//
export const listApaveApps = () =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.apaveApps)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const listPermissions = () =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.permissions)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
// import users
export const importUsers = payload =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.importUsers, payload)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const importEstablishment = payload =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.importEstablishment, payload)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const importExclusion = payload =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.importExclusion, payload)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const listLeads = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.leads}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const deleteLead = id =>
  new Promise((resolve, reject) => {
    deleteData(`${apiEndPoints.leads}/${id}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
//catalogue
export const listCatalogues = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.catalogue}all?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const createCatalogue = data =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.catalogue, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const updateCatalogue = (id, data) =>
  new Promise((resolve, reject) => {
    patchData(apiEndPoints.catalogue + id, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const getCatalogue = id =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.catalogue + id)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const deleteCatalogue = (id) =>
  new Promise((resolve, reject) => {
    deleteData(apiEndPoints.catalogue + id)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

//product
export const listProducts = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.product}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const createProduct = data =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.product, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const updateProduct = (id, data) =>
  new Promise((resolve, reject) => {
    patchData(apiEndPoints.product + id, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const getProduct = id =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.product + id)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const deleteProduct = (id) =>
  new Promise((resolve, reject) => {
    deleteData(apiEndPoints.product + id)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const runUberallAPI = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.location + '/runAPI', body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const importProducts = payload =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.product + 'imports', payload)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => reject(err));
  });

//Training product
export const callTrainingAPI = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.training, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const listGPLocations = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.location}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const listMessages = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.request}/messages?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const sendMessage = body =>
  new Promise((resolve, reject) => {
    postData(`${apiEndPoints.request}/messages`, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

// team
export const createTeam = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.teams, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const getTeam = id =>
  new Promise((resolve, reject) => {
    getData(`${apiEndPoints.teams}/${id}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const updateTeam = (id, data) =>
  new Promise((resolve, reject) => {
    patchData(`${apiEndPoints.teams}/${id}`, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const listTeams = () =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.teams)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const deleteTeam = id =>
  new Promise((resolve, reject) => {
    deleteData(`${apiEndPoints.teams}/${id}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

// sites
export const createSite = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.sites, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const getSite = id =>
  new Promise((resolve, reject) => {
    getData(`${apiEndPoints.sites}/${id}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const updateSite = (id, data) =>
  new Promise((resolve, reject) => {
    patchData(`${apiEndPoints.sites}/${id}`, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const listSites = () =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.sites)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const deleteSite = id =>
  new Promise((resolve, reject) => {
    deleteData(`${apiEndPoints.sites}/${id}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const createSiteSubmission = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.sitesubmission, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const listSiteSubmissions = () =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.leads)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const deleteSiteSubmission = body =>
  new Promise((resolve, reject) => {
    patchData(apiEndPoints.leads, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const listApaveUsers = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.apaveusers}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const updateLead = (id, data) =>
  new Promise((resolve, reject) => {
    patchData(`${apiEndPoints.leads}/orders/${id}`, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const listTrainingProducts = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.trainingproducts}/listall?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
const APP_ID = '10000';
export const resetPasswordWithToken = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.resetPasswordWithToken, { ...body, appId: APP_ID })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const sendResetPasswordLink = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.sendResetPasswordLink, { ...body, appId: APP_ID })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const sendVerificationEmail = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.sendVerificationEmail, { ...body, appId: APP_ID })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const verifyEmail = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.verifyEmail, { ...body, appId: APP_ID })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const verifyResetPasswordLink = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.verifyResetPasswordLink, { ...body, appId: APP_ID })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const listLogs = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.logs}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
//Group
export const createGroup = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.groups, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const listGroups = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.groups}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const deleteGroup = id =>
  new Promise((resolve, reject) => {
    deleteData(`${apiEndPoints.groups}/${id}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const listSessions = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.sessions}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const deleteSession = id =>
  new Promise((resolve, reject) => {
    deleteData(`${apiEndPoints.sessions}/${id}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const listFormations = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.formation}/admin?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const importFormations = payload =>
  new Promise((resolve, reject) => {
    postData(`${apiEndPoints.formation}/import`, payload)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const deleteFormation = id =>
  new Promise((resolve, reject) => {
    deleteData(`${apiEndPoints.formation}/${id}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const listCodeProducts = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.codeproducts}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const importDocuments = payload =>
  new Promise((resolve, reject) => {
    postData(`${apiEndPoints.documents}/import`, payload)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const listCrossUsers = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.submissions}/users?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};

export const listCrossSubmissions = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.submissions}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};

export const updateCrossSubmission = payload =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.submissions, payload)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const updateCompteSel = (id, data) =>
  new Promise((resolve, reject) => {
    postData(`${apiEndPoints.compteSel}/${id}`, data)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const listUserNotifications = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.notifications}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};

export const importAssociatedProducts = payload =>
  new Promise((resolve, reject) => {
    postData(`${apiEndPoints.trainingproducts}/import_associated_products`, payload)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const listCompteSels = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.compteSel}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};

export const listCaracteristiques = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.caracteristiques}/users?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};