import {
    Select,
    Typography
} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {

    },
    label: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0.5)
    },
    icon: {
        top: 'calc(50% - 12px)',
        right: 12,
        position: 'absolute',
    }
}));

const CustomCommonSelect = ({
    label,
    className,
    required,
    children,
    hideLabel,
    labelStyle,
    ...rest
}) => {
    const classes = useStyles();
    return (
        <>
            {!hideLabel && <Typography className={clsx(classes.label, labelStyle)}><strong>{label}{required ? <span style={{ color: '#DF7C70' }}>*</span> : ''}</strong></Typography>}
            <Select
                {...rest}
                className={clsx(classes.root, className)}
                label={''}
            //IconComponent={(props) => (<IconDropdown {...props} style={{ right: 10, top: "calc(50% - 4px)" }} />)}
            >
                {children}
            </Select>
        </>
    );
};

export default CustomCommonSelect;
