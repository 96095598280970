import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv/lib';
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import PageBase from 'components/PageBase';
import {
  makeStyles,
  Button,
  MenuItem,
  Tooltip,
  AppBar,
  IconButton,
  Grid,
  Typography
} from '@material-ui/core';
import { store } from 'react-notifications-component';
import useRouter from 'utils/useRouter';
import {
  profileURL,
  ROLE_USER,
  EXPORT_MODE_EXCEL,
  EXPORT_MODE_CSV,
  ROLE_ADMIN
} from 'config';
import * as API from 'services/api';
import {
  get10LastLetters,
  getHHMMDDMMYYYYFromTimeStamp,
} from 'utils/validations';
import { setLoading } from 'redux/actions/app';
import InfiniteScroll from 'react-infinite-scroller';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import MUIDataTable from 'mui-datatables';
import EditIcon from '@material-ui/icons/Edit';
import RemoveUserModal from 'components/Modals/RemoveUserModal';
import { appProfileRoleSelector } from 'redux/selectors';
import ClientSelect from 'components/Select/ClientSelect';
import ImportUsersModal from 'components/Modals/ImportUsersModal';
import CustomTable from 'components/CustomTable'
import SearchBar from 'components/CustomTable/searchbar'
import Filters from 'components/CustomTable/filters'
import { includesSearchWord } from 'utils'
import { TabPanel, StyledTabs, StyledTab } from 'components/CustomTab'
import useHomeContext from 'layouts/HomeLayout/context'

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 15,
  },
  title: {
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 15,
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  avatar: {
    margin: 5,
    cursor: 'pointer',
  },
  icon: {
    fill: 'inherit !important'
  },
  password: {
    minWidth: 80,
  },
  actions: {
    minWidth: 80,
  },
  clients: {
    flex: 1,
    marginRight: 20,
    marginBottom: 0,
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  csv: {
    display: 'none',
  },
  excel: {
    marginRight: 20,
    backgroundColor: '#040f40',
    padding: 10,
    color: '#fff',
    borderRadius: 15,
    '&:hover': {
      backgroundColor: '#00B0EC',
    },
  },
  spinner: {
    display: 'block',
    margin: 'auto',
    marginTop: 20,
    marginBottom: 20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  rightdom: {
    marginLeft: 'auto'
  },
  table: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5)
  },
  appbar: {
    boxShadow: 'none',
    padding: theme.spacing(0, 5),
    backgroundColor: theme.palette.background.default,
  },
  tabsContainer: {
    color: theme.palette.app.primary
  },
  tabContainer: {
    maxWidth: 'unset'
  }
}));

const PERPAGE = 30;

const Users = ({ intl: { formatMessage } }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [allUsersLoaded, setAllUsersLoaded] = useState(false);
  const csvLink = useRef();
  const excelExporter = useRef();
  const router = useRouter();
  const [removeModalInfo, setRemoveModalInfo] = useState({
    open: false,
    id: null,
  });

  const [clientId, setClientId] = useState(-1);
  const role = useSelector(appProfileRoleSelector);

  const [importUsersModalVisible, setImportUsersModalVisible] = useState(false);

  const [openFilter, setOpenFilter] = useState(false)
  const [filters, setFilters] = useState({})
  const [keyword, setKeyword] = useState('')

  const [teams, setTeams] = useState([]);
  useEffect(() => {
    API.listTeams({})
      .then(res => {
        if (res.code === 200) {
          setTeams(res.data);
        }
        dispatch(setLoading(false));
      })
      .catch(err => {
      });
  }, []);

  const toggleUsersImportModal = () => {
    setImportUsersModalVisible(
      importUsersModalVisible => !importUsersModalVisible,
    );
  };

  const onUploadUsers = (file, type, clientId) => {
    dispatch(setLoading(true));
    const formData = new FormData();
    formData.append('type', type);
    formData.append('file', file);
    formData.append('role', ROLE_USER);
    formData.append('clientId', clientId);
    API.importUsers(formData)
      .then(res => {
        dispatch(setLoading(false));
        if (res.code === 200) {
          toggleUsersImportModal();
          window.location.reload();
        } else {
          store.addNotification({
            message: res.message,
            type: 'danger',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      })
      .catch(err => {
        dispatch(setLoading(false));
      });
  };

  const handleCloseRemoveModal = confirmed => {
    if (confirmed) {
      deleteUser(removeModalInfo.id);
    }
    setRemoveModalInfo({
      open: false,
      id: null,
    });
  };

  const loadUsers = (refresh) => {
    //dispatch(setLoading(true));
    setUsersLoading(true);
    const page = refresh ? 1 : parseInt(users.length / PERPAGE + 1, 10);

    const params = {
      page,
      perPage: PERPAGE,
      isApave: true,
      ...keyword && keyword.length > 0 ? { keyword } : {}
    };

    API.listUsers(params)
      .then(res => {
        if (res.code === 200) {
          setUsers(users => refresh ? res.users : [...users, ...res.users]);
          setAllUsersLoaded(res.users.length < PERPAGE);
        }
        dispatch(setLoading(false));
        setUsersLoading(false);
      })
      .catch(err => {
        dispatch(setLoading(false));
        setUsersLoading(false);
      });
  };

  useEffect(
    () => {
      setData([]);
      setUsers([]);
      setUsersLoading(true);
      setAllUsersLoaded(false);
      loadUsers(true);
    },
    [clientId, keyword],
  );
  const loadMore = () => {
    if (allUsersLoaded || usersLoading) {
      return;
    }
    loadUsers();
  };

  const exportExcel = () => {
    if (data.length > 0) {
      return excelExporter.current.save();
    }
    loadExportData(1, EXPORT_MODE_EXCEL);
  };

  const exportCSV = () => {
    if (data.length > 0) {
      return csvLink.current.link.click();
    }
    loadExportData(1, EXPORT_MODE_CSV);
  };

  const exportData = (exportMode) => {
    if (exportMode === EXPORT_MODE_EXCEL) {
      excelExporter.current.save();
    } else {
      csvLink.current.link.click();
    }
  };

  const loadExportData = (page = 1, exportMode) => {
    const params = {
      page,
      perPage: PERPAGE,
      ...keyword && keyword.length > 0 ? { keyword } : {}
    };

    if (clientId !== -1) {
      params.clientId = clientId;
    }
    dispatch(setLoading(true));
    API.listUsers(params).then(res => {
      if (res.code === 200) {
        const newUsers = res.users;
        setData(prev => [...prev, ...newUsers]);
        if (newUsers.length < PERPAGE) {
          dispatch(setLoading(false));
          exportData(exportMode);
        } else {
          loadExportData(page + 1, exportMode);
        }
      }
    });
  };

  const onSaveUser = id => {
    const userInfo = users.find(e => e.id === id);
    if (userInfo) {
      const payload = {
        status: userInfo.status,
        memberShip: userInfo.memberShip,
      };
      dispatch(setLoading(true));
      API.updateManager(id, payload)
        .then(res => {
          dispatch(setLoading(false));
          if (res.code === 200) {
            store.addNotification({
              message: 'User is updated successfully',
              type: 'success',
              container: 'top-right',
              animationIn: ['animated', 'fadeIn'],
              animationOut: ['animated', 'fadeOut'],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
        })
        .catch(err => {
          dispatch(setLoading(false));
        });
    }
  };

  const onDeleteUser = id => {
    setRemoveModalInfo({
      open: true,
      id,
    });
  };

  const deleteUser = id => {
    dispatch(setLoading(true));

    API.deleteUser(id)
      .then(res => {
        dispatch(setLoading(false));
        if (res.code === 200) {
          store.addNotification({
            message: 'User is removed successfully',
            type: 'success',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          setUsers(users => users.filter(e => e.id !== id));
        }
      })
      .catch(err => {
        dispatch(setLoading(false));
      });
  };

  const onAdd = () => {
    router.history.push('/users/create', { internal: true });
  };

  const excelData = data.map(user => ({
    id: get10LastLetters(user.id),
    avatar: user.picture === undefined ? '' : profileURL + user.picture,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    company: user.company,
    zipCode: user.zipCode,
    status: user.status,
    numLogin: parseInt(user.numLogin) + 1,
    lastLogin: user.lastLogin ? getHHMMDDMMYYYYFromTimeStamp(user.lastLogin) : "",
    createdAt: getHHMMDDMMYYYYFromTimeStamp(user.createdAt),
  }));

  const headers = [
    { label: formatMessage({ id: 'no' }), key: 'id' },
    { label: formatMessage({ id: 'avatar' }), key: 'avatar' },
    { label: formatMessage({ id: 'first_name' }), key: 'firstName' },
    { label: formatMessage({ id: 'last_name' }), key: 'lastName' },
    { label: formatMessage({ id: 'email' }), key: 'email' },
    { label: formatMessage({ id: 'company' }), key: 'company' },
    { label: formatMessage({ id: 'zipcode' }), key: 'zipCode' },
    { label: formatMessage({ id: 'status' }), key: 'status' },
    { label: formatMessage({ id: 'numLogin' }), key: 'numLogin' },
    { label: formatMessage({ id: 'lastLogin' }), key: 'lastLogin' },
    { label: formatMessage({ id: 'created_at' }), key: 'createdAt' },
  ];

  const tableColumns = [
    {
      name: 'id',
      label: formatMessage({
        id: 'id',
      }),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => get10LastLetters(value),
      },
    },
    {
      name: 'role',
      label: formatMessage({ id: 'role' }),
      options: { filter: true, sort: true, customBodyRender: value => formatMessage({ id: 'role.' + value }) },
    },
    {
      name: 'firstName',
      label: formatMessage({ id: 'first_name' }),
      options: { filter: false, sort: true },
    },
    {
      name: 'lastName',
      label: formatMessage({ id: 'last_name' }),
      options: { filter: false, sort: true },
    },
    {
      name: 'email',
      label: formatMessage({ id: 'email' }),
      options: { filter: false, sort: true },
    },
    {
      name: 'team',
      label: formatMessage({
        id: 'teams',
      }),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return value.map(item => teams.find(el => el.id == item)).filter(item => !!item).map(item => item.title).join(", ")
        }
      },
    },
    {
      name: 'status',
      label: formatMessage({ id: 'status' }),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <FormattedMessage id={`home.users.${value.toLowerCase()}`} />
        ),
      },
    },
    {
      name: 'numLogin',
      label: formatMessage({ id: 'numLogin' }),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Typography style={{ textAlign: 'center' }}>{parseInt(value) + 1}</Typography>
        ),
      },
    },
    {
      name: 'lastLogin',
      label: formatMessage({ id: 'lastLogin' }),
      options: {
        filter: true,
        sort: true,
        customBodyRender: value => value ? getHHMMDDMMYYYYFromTimeStamp(value) : "",
      },
    },
    {
      name: 'createdAt',
      label: formatMessage({
        id: 'created_at',
      }),
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => getHHMMDDMMYYYYFromTimeStamp(value),
      },
    },
    {
      name: 'actions',
      label: formatMessage({
        id: 'actions',
      }),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <React.Fragment>
            <Tooltip title={formatMessage({ id: 'tooltip.delete' })}>
              <IconButton onClick={() => {
                onDeleteUser(tableMeta.rowData[0]); // id
              }}>
                <DeleteIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
            <Tooltip title={formatMessage({ id: 'edit' })}>
              <IconButton onClick={() => {
                router.history.push(`/user`, { id: tableMeta.rowData[0], internal: true });
              }}>
                <EditIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        ),
      },
    },
  ];

  const showClients = role === ROLE_ADMIN;

  let datasource = users
  if (!Object.values(filters).find(item => !item)) {
    datasource = datasource.filter(item => {
      const fields = Object.keys(filters)
      const inValid = !!fields.find(el => filters[el] && item[el] != filters[el])
      return !inValid
    })
  }
  const handleTabChange = (event, newValue) => {
    if (newValue == 1) {
      router.history.push("/internal/teams")
    } else if (newValue == 2) {
      router.history.push("/internal/admins")
    }
  };
  return (
    <PageBase title={formatMessage({ id: 'navbar.users.internal' })}
      rightComponent={<div className={classes.rightdom}>
        <Button onClick={onAdd} classes={{ root: classes.excel }}>
          <FormattedMessage id="home.user.create" />
        </Button>
        <Button onClick={exportExcel} classes={{ root: classes.excel }}>
          <FormattedMessage id="export_xls" />
        </Button>
        <Button onClick={exportCSV} classes={{ root: classes.excel }}>
          <FormattedMessage id="export_csv" />
        </Button>
        <CSVLink
          data={excelData}
          headers={headers}
          className={classes.csv}
          filename="users.csv"
          ref={csvLink}
        >
          CSV
        </CSVLink>
      </div>}>
      <div>
        <AppBar position="static" className={classes.appbar}>
          <StyledTabs value={0} onChange={handleTabChange} aria-label="services tabs" className={classes.tabsContainer}>
            <StyledTab className={classes.tabContainer} label={<FormattedMessage id="navbar.users" />} />
            <StyledTab className={classes.tabContainer} label={<FormattedMessage id="navbar.users.internal.teams" />} />
            <StyledTab className={classes.tabContainer} label={<FormattedMessage id="navbar.admins" />} />
          </StyledTabs >
        </AppBar>
        <Grid container >
          <Grid item sm={openFilter ? 9 : 12} style={{ padding: '0px !important' }}>
            <SearchBar
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              keyword={keyword}
              setKeyword={setKeyword}
            />
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={!allUsersLoaded}
              className={classes.table}
            >
              <CustomTable
                columns={tableColumns}
                data={datasource}
                options={{
                  pagination: false,
                  print: false,
                  download: false,
                  selectableRows: 'none',
                  searchable: false,
                  viewColumns: false,
                  elevation: 0,
                  filter: false,
                  search: false,
                  textLabels: {
                    body: {
                      noMatch: formatMessage({
                        id: 'muitable.placeholder.nomatch_records',
                      }),
                    },
                    filter: {
                      all: formatMessage({ id: 'all' }),
                      title: formatMessage({ id: 'filters' }),
                      reset: formatMessage({ id: 'reset' }),
                    },
                  },
                }}
              />
            </InfiniteScroll>
          </Grid>
          {
            openFilter &&
            <Filters
              setOpenFilter={setOpenFilter}
              setOnFilter={setFilters}
              columns={tableColumns}
              data={users}
            />
          }
        </Grid>
        <RemoveUserModal
          open={removeModalInfo.open}
          description={formatMessage({
            id: 'comments.please_confirm_again_to_delete_user',
          })}
          onCloseModal={handleCloseRemoveModal}
        />
        <ImportUsersModal
          addFree
          visible={importUsersModalVisible}
          toggleModal={toggleUsersImportModal}
          onUpload={onUploadUsers}
        />
        <ExcelExport
          data={excelData}
          fileName="users.xlsx"
          ref={excelExporter}
        >
          <ExcelExportColumn
            field="id"
            title={formatMessage({ id: 'id' })}
            width={30}
            cellOptions={{ fontSize: 12 }}
            headerCellOptions={{ fontSize: 14 }}
          />
          <ExcelExportColumn
            field="avatar"
            title={formatMessage({ id: 'avatar' })}
            width={150}
            cellOptions={{ fontSize: 12 }}
            headerCellOptions={{ fontSize: 14 }}
          />
          <ExcelExportColumn
            field="firstName"
            title={formatMessage({ id: 'first_name' })}
            width={150}
            cellOptions={{ fontSize: 12 }}
            headerCellOptions={{ fontSize: 14 }}
          />
          <ExcelExportColumn
            field="lastName"
            title={formatMessage({ id: 'last_name' })}
            width={150}
            cellOptions={{ fontSize: 12 }}
            headerCellOptions={{ fontSize: 14 }}
          />
          <ExcelExportColumn
            field="email"
            title={formatMessage({ id: 'email' })}
            width={200}
            cellOptions={{ fontSize: 12 }}
            headerCellOptions={{ fontSize: 14 }}
          />
          <ExcelExportColumn
            field="company"
            title={formatMessage({ id: 'company' })}
            width={200}
            cellOptions={{ fontSize: 12 }}
            headerCellOptions={{ fontSize: 14 }}
          />
          <ExcelExportColumn
            field="zipCode"
            title={formatMessage({ id: 'zipcode' })}
            width={200}
            cellOptions={{ fontSize: 12 }}
            headerCellOptions={{ fontSize: 14 }}
          />
          <ExcelExportColumn
            field="status"
            title={formatMessage({ id: 'status' })}
            width={100}
            cellOptions={{ fontSize: 12 }}
            headerCellOptions={{ fontSize: 14 }}
          />
          <ExcelExportColumn
            field="numLogin"
            title={formatMessage({ id: 'numLogin' })}
            width={100}
            cellOptions={{ fontSize: 12 }}
            headerCellOptions={{ fontSize: 14 }}
          />
          <ExcelExportColumn
            field="lastLogin"
            title={formatMessage({ id: 'lastLogin' })}
            width={100}
            cellOptions={{ fontSize: 12 }}
            headerCellOptions={{ fontSize: 14 }}
          />
          <ExcelExportColumn
            field="createdAt"
            title={formatMessage({ id: 'created_at' })}
            width={250}
            cellOptions={{ fontSize: 12 }}
            headerCellOptions={{ fontSize: 14 }}
          />
        </ExcelExport>
      </div>
    </PageBase>
  );
};

Users.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};

export default injectIntl(Users);
